<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import inobounce from "inobounce";
export default {
  created() {
    // let u = navigator.userAgent;
    // if (u.indexOf("iPhone") > -1) {
    //   inobounce.enable();
    // }
  },
  // beforeDestroy() {
  //   inobounce.disable();
  // },
  beforeMount() {
    // 禁用双指放大
    document.documentElement.addEventListener(
      "touchstart",
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      },
      {
        passive: false,
      }
    );

    // 禁用双击放大
    var lastTouchEnd = 0;
    document.documentElement.addEventListener(
      "touchend",
      function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      },
      {
        passive: false,
      }
    );
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
}
</style>
