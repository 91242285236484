<template>
  <div class="home">
    <div class="first">
      <img class="first-back" src="../../assets/may-day/icon-10.png" alt="" />

      <img class="first-img0" src="../../assets/may-day/icon-12.png" alt="" />

      <img class="first-img1" src="../../assets/may-day/icon-13.png" alt="" />
    </div>

    <div class="second">
      <img class="second-back" src="../../assets/may-day/icon-9.png" alt="" />
      <img class="second-icon0" src="../../assets/may-day/icon-0.png" alt="" />
      <img class="second-icon1" src="../../assets/may-day/icon-8.png" alt="" />
      <img class="second-icon2" src="../../assets/may-day/icon-1.png" alt="" />
      <div class="second-icon3">
        <div class="icon-3-list" v-for="(item, index) in 3" :key="index">
          {{ index == 0 ? "排名" : index == 1 ? "用户ID" : "拉新人数" }}
        </div>
      </div>

      <div class="second-icon4">
        <img class="icon4-back" src="../../assets/may-day/icon-3.png" alt="" />
        <div class="icon4-div">
          <div
            class="div-list"
            v-for="(item, index) in InviteList"
            :key="index"
          >
            <div class="list-left">
              <img
                v-if="index == 0"
                src="../../assets/may-day/icon-6.png"
                alt=""
              />

              <img  v-if="index == 1" src="../../assets/may-day/icon-4.png" alt="">

              <img  v-if="index == 2" src="../../assets/may-day/icon-5.png" alt="">


              <div v-else>{{ index != 0 && index != 1 && index != 2? index: '' }}</div>

            </div>
            <div class="list-center">{{ item.phone }}</div>
            <div class="list-right">{{ item.inviteUserNum5 }}</div>
          </div>
        </div>
      </div>

      <div class="second-icon5">
        <img
          class="icon5-header"
          src="../../assets/may-day/icon-7.png"
          alt=""
        />
        <img class="icon5-back" src="../../assets/may-day/icon-8.png" alt="" />

        <div class="icon5-div">
          <div class="div-list" v-for="(item, index) in 7" :key="index">
            {{
              index == 0
                ? " 雅迪电动车一辆 (小金刚)价值￥2100  （300人）"
                : index == 1
                ? " 曲记星平板电脑一台  价值￥490（≥150人）"
                : index == 2
                ? "Dior口红/美得电饭煲 价值￥380（≥100人）"
                : index == 3
                ? "志高豆浆机 价值￥230  （≥80人）"
                : index == 4
                ? "皇太太智能果汁杯/ 价值￥160（≥50人）"
                : index == 5
                ? "苹果蓝牙耳机 价值￥118（≥20人）"
                : "泡泡盐   价值￥38 （≥10人）"
            }}
          </div>
        </div>

        <img
          class="icon5-award"
          src="../../assets/may-day/icon-11.png"
          alt=""
        />
      </div>

      <div class="second-icon6">
        <div>活动参与须知</div>
        <div>1、新老用户均可邀请好友；</div>
        <div>
          2、活动期间，被邀请人通过好友分享的二维码进行扫码完成注册并且打开APP视为有效邀请；
        </div>
        <div>
          3、活动结束后，请在5月10号前联系平台在线客服进行领取奖励，否则示为放弃。
        </div>
      </div>

      <div class="second-icon7">
        <div class="icon7-title">
          客服热线：400一609一8779 活动最终解释权归乡米网所有
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmayDayInvitenum } from "../../api/activity";

export default {
  data() {
    return {
      InviteList: [],
    };
  },
  created() {
    this.getmayDayInvitenumMet();
  },
  methods: {
    // 请求数据
    getmayDayInvitenumMet() {
      let params = {};

      getmayDayInvitenum(params).then((res) => {
        if (res.code == 200) {
          this.InviteList = res.content.list;
          console.log("请求数据成功！", this.InviteList);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  position: relative;

  .first {
    width: 100%;
    position: relative;
    display: flex;

    .first-back {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
    }

    .first-img0 {
      position: absolute;
      z-index: 2;
      top: 9.714vw;
      right: 19.14vw;
      width: 13.71vw;
      height: 13.71vw;
    }

    .first-img1 {
      position: absolute;
      z-index: 2;
      left: 2.28vw;
      top: 7.714vw;
      width: 13.71vw;
      height: 13.71vw;
    }
  }

  .second {
    min-height: 22vw;
    width: 100%;
    margin-top: -16vw;
    position: relative;

    .second-back {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    .second-icon0 {
      width: 96%;
      position: absolute;
      top: 3.5vw;
      left: 0;
      z-index: 1;
      margin: 0 2%;
    }

    .second-icon1 {
      position: absolute;
      width: 94%;
      margin: 0 2.5% 0 3.5%;
      left: 0;
      top: 5vw;
      z-index: 1;
      height: 120vw;
    }

    .second-icon2 {
      position: absolute;
      width: 60%;
      z-index: 2;
      left: 0;
      top: -1.15vw;
      margin: 0 20%;
    }

    .second-icon3 {
      position: absolute;
      width: 84%;
      z-index: 2;
      left: 0;
      top: 12.6vw;
      margin: 0 8%;
      background: #9afbb6;
      height: 9.15vw;
      border-radius: 3.42vw;
      display: flex;
      align-items: center;

      .icon-3-list {
        font-size: 3.2vw;
        font-weight: 900;
        text-align: center;
        color: #353636;
      }

      .icon-3-list:nth-child(1) {
        width: 30%;
      }

      .icon-3-list:nth-child(2) {
        width: 40%;
        text-align: left;
      }

      .icon-3-list:nth-child(3) {
        width: 30%;
      }
    }

    .second-icon4 {
      position: absolute;
      width: 84%;
      z-index: 2;
      left: 0;
      top: 23vw;
      margin: 0 8%;

      .icon4-back {
        width: 100%;
        height: 96vw;
        position: absolute;
        left: 0;
      }

      .icon4-div {
        width: 100%;
        position: relative;

        .div-list {
          width: 96%;
          margin-top: 1.4vw;
          height: 7.74vw;
          margin-left: 1.4%;
          display: flex;
          align-items: center;
          background: #ddffe7;
          border-radius: 1.15vw;
          font-weight: 800;
          font-size: 3.2vw;

          .list-left {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 7.74vw;
              height: 7vw;
            }
          }

          .list-center {
            width: 40%;
            text-align: left;
          }

          .list-right {
            width: 30%;
            text-align: center;
          }
        }

        .div-list:first-child {
          margin-top: 2.51vw;
        }
      }
    }

    .second-icon5 {
      position: absolute;
      height: 113vw;
      top: 130vw;
      left: 0;

      .icon5-header {
        position: absolute;
        width: 60%;
        z-index: 2;
        left: 0;
        top: -1.15vw;
        margin: 0 20%;
      }

      .icon5-back {
        position: relative;
        width: 94%;
        margin: 0 2.5% 0 3.5%;
        left: 0;
        top: 4.58vw;
        z-index: 1;
        height: 108vw;
      }

      .icon5-div {
        position: absolute;
        z-index: 2;
        top: 13.8vw;
        width: 84%;
        margin: 0 8%;

        .div-list {
          background: #9bfbb7;
          margin-bottom: 3.42vw;
          border-radius: 2.74vw;
          padding: 2.4vw 3.57vw;
          box-sizing: border-box;
          font-size: 3.5vw;
          font-weight: 800;
          text-align: left;
        }
      }

      .icon5-award {
        position: absolute;
        width: 17.14vw;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    }

    .second-icon6 {
      font-size: 3.2vw;
      font-weight: 800;
      position: absolute;
      text-align: left;
      width: 90%;
      top: 250.14vw;
      margin: 0 5%;

      div {
        margin-bottom: 1.14vw;
      }
    }

    .second-icon7 {
      font-size: 3.2vw;
      font-weight: 800;
      position: absolute;
      text-align: center;
      bottom: 9.14vw;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      top: 305vw;
    }
  }
}
</style>
